import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../css/lcd-departments.css'; 
import placeholderImg from './imgs/placeholder.jpg';

const LCDDepartments = () => {
  const [departments, setDepartments] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('Ban Chấp Hành');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const strapiBaseURL = 'https://fit.neu.edu.vn/admin';

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get('https://fit.neu.edu.vn/admin/api/lcd-page?populate=deep');
        const data = response.data.data.attributes.departments;
        setDepartments(data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading departments data.</p>;

  const selectedTeamData = departments.find((team) => team.name === selectedTeam);

  return (
    <div className="container my-5">
      <h2 className="text-center mb-3 fs-1">Cơ Cấu Tổ Chức Liên Chi Đoàn</h2>
      <h3 className="text-center mb-5">Nhiệm kỳ 2024-2027</h3>

      <div className="d-flex flex-wrap justify-content-center mb-4 department-buttons">
        {departments.map((team) => (
          <button
            key={team.id}
            className={`btn ${selectedTeam === team.name ? 'btn-primary' : 'btn-outline-primary'} mx-2 department-button`}
            onClick={() => setSelectedTeam(team.name)}
          >
            {team.name}
          </button>
        ))}
      </div>

      {selectedTeamData && (
        <>
          <div className="text-center mb-5">
            <h3 className="mb-3">{selectedTeamData.name}</h3>
          </div>

          <div className={`row justify-content-center ${selectedTeamData.members.length === 2 ? 'two-members-row' : ''}`}>
            {selectedTeamData.members.map((member, index) => (
              <div
                key={index}
                className={`${
                  selectedTeamData.members.length === 2
                    ? 'col-md-5'
                    : selectedTeamData.members.length === 3
                    ? 'col-md-4'
                    : 'col-md-3'
                } mb-4 d-flex justify-content-center`}
              >
                <div className="card text-center border-0">
                  <img
                    src={
                      member.avatar?.data?.attributes?.formats?.thumbnail?.url
                        ? `${strapiBaseURL}${member.avatar.data.attributes.formats.thumbnail.url}`
                        : placeholderImg
                    }
                    className="card-img-top rounded-circle mx-auto mt-3"
                    alt={member.name}
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{member.name}</h5>
                    <p className="card-text text-muted">{member.position}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default LCDDepartments;