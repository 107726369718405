import React from 'react';
import { Toast } from 'react-bootstrap';
import '../../css/raise-hand-cl.css';

const RaiseHandButton = ({ isRaised, toggleRaiseHand }) => {
  return (
    <div className="raise-hand-container">
      <button
        className={`raise-hand-btn ${isRaised ? 'raised' : ''}`}
        onClick={toggleRaiseHand}
      >
        ✋
      </button>
    </div>
  );
};

export default RaiseHandButton;