import React from 'react';
import partner1 from '../../imgs/partner-1.png';
import partner2 from '../../imgs/partner-2.png';
import partner3 from '../../imgs/partner-3.png';
import partner4 from '../../imgs/partner-4.png';
import partner5 from '../../imgs/partner-5.png';
import partner6 from '../../imgs/partner-6.png';
import partner7 from '../../imgs/partner-7.png';
import partner8 from '../../imgs/partner-8.png';
import partner9 from '../../imgs/partner-9.png';
import partner10 from '../../imgs/partner-10.png';
import partner11 from '../../imgs/partner-11.png';
import partner12 from '../../imgs/partner-12.png';
import partner13 from '../../imgs/partner-13.png';
import partner14 from '../../imgs/partner-14.png';
import partner15 from '../../imgs/partner-15.png';
import partner16 from '../../imgs/partner-16.png';
import partner17 from '../../imgs/partner-17.png';

function Partners() {
  const imageStyle = {
    width: '200%',
    height: '100%',
    objectFit: 'contain',
    padding: '0.5rem',
  };

  const rowStyle = {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'nowrap',
    overflow: 'hidden', 
  };

  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="text-center">
           <h6 className="text-uppercase">
            <i className="fa fa-handshake fa-2x text-primary"></i>
          </h6>
          <h1 className="mb-5 fs-1 text-primary">- Danh sách đối tác -</h1>
        </div>
        <div id="partnersCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row" style={rowStyle}>
                <div className="col-2 col-md-1">
                  <img src={partner1} style={imageStyle} alt="Partner 1" />
                </div>
                <div className="col-2 col-md-1">
                  <img src={partner2} style={imageStyle} alt="Partner 2" />
                </div>
                <div className="col-2 col-md-1">
                  <img src={partner3} style={imageStyle} alt="Partner 3" />
                </div>
                <div className="col-2 col-md-1">
                  <img src={partner4} style={imageStyle} alt="Partner 4" />
                </div>
                <div className="col-2 col-md-1">
                  <img src={partner5} style={imageStyle} alt="Partner 5" />
                </div>
                <div className="col-2 col-md-1">
                  <img src={partner6} style={imageStyle} alt="Partner 6" />
                </div>
                <div className="col-2 col-md-1">
                  <img src={partner7} style={imageStyle} alt="Partner 7" />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row" style={rowStyle}>
                <div className="col-2 col-md-1">
                  <img src={partner8} style={imageStyle} alt="Partner 8" />
                </div>
                <div className="col-2 col-md-1">
                  <img src={partner9} style={imageStyle} alt="Partner 9" />
                </div>
                <div className="col-2 col-md-1">
                  <img src={partner10} style={imageStyle} alt="Partner 10" />
                </div>
                <div className="col-2 col-md-1">
                  <img src={partner11} style={imageStyle} alt="Partner 11" />
                </div>
                <div className="col-2 col-md-1">
                  <img src={partner12} style={imageStyle} alt="Partner 12" />
                </div>
                <div className="col-2 col-md-1">
                  <img src={partner13} style={imageStyle} alt="Partner 13" />
                </div>
                <div className="col-2 col-md-1">
                  <img src={partner14} style={imageStyle} alt="Partner 14" />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row" style={rowStyle}>
                <div className="col-2 col-md-1">
                  <img src={partner15} style={imageStyle} alt="Partner 15" />
                </div>
                <div className="col-2 col-md-1">
                  <img src={partner16} style={imageStyle} alt="Partner 16" />
                </div>
                <div className="col-2 col-md-1">
                  <img src={partner17} style={imageStyle} alt="Partner 17" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;