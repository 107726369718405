// Header.js
import React, { useEffect, useState, useRef } from "react";

import app from "../../firebase";
import firebase from "firebase/compat/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoginModal from "../../modals/LoginModal";
import AddRoomModal from "../../modals/AddRoomModal";
import QRModal from "../../modals/QRModal";
import UserMenu from "./UserMenu";
import { Button } from "react-bootstrap";
import { getDatabase, set, ref } from "firebase/database";
import { HiQrCode } from "react-icons/hi2";
import { GiHamburgerMenu } from "react-icons/gi";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { IoDocumentTextOutline } from "react-icons/io5";
import { RiSlideshow2Line } from "react-icons/ri";
import { LuBook } from "react-icons/lu";
import { RxUpdate } from "react-icons/rx";
import RaiseHandButton from "./RaiseHandButton";

const ROOM_TEST = "tO683y";

const Header = ({
  stepsData,
  currentStep,
  handleStepClick,
  showStepList,
  onOptionClick,
  onTitleClick,
  room,
  onUserLogin,
  onDocClick,
  onSlideClick,
  onBookClick,
  chap,
  slideTitle,
  display,
}) => {
  const [user, setUser] = useState(null);
  const [teacher, setTeacher] = useState(null);
  const [showQRModal, setShowQRModal] = useState(false);
  const isRoomInPath = window.location.pathname.includes("room");
  const [isRaised, setIsRaised] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const navbarRef = useRef(null);
  // Use ROOM_TEST if room is null
  const effectiveRoom = room || { roomID: ROOM_TEST, docID: "testDocID" };
  useEffect(() => {
    if (room) {
      setTeacher(room?.userID);
    }
    const handleOutsideClick = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbar = document.getElementById("navbarNav");
        if (navbar?.classList?.contains("show")) {
          navbar?.classList?.remove("show");
        }
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [room]);

  useEffect(() => {
    const auth = getAuth(app);
    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        onUserLogin(currentUser);
      } else {
        setUser(null);
        onUserLogin(null);
      }
      setLoadingLogin(false);
    });
    if (stepsData?.listChapter) {
      setSelectedOption(stepsData.listChapter[chap - 1]);
      setSelectedIndex(chap - 1);
    }
    if (isRoomInPath && !user) {
      setShowLogin(true);
    }
  }, [onUserLogin, stepsData, chap, user, room, isRoomInPath]);
  const toggleRaiseHand = async () => {
    if (!effectiveRoom.roomID || !user) {
      console.log("Room ID or User is null");
      return;
    }

    const status = isRaised ? "LOWERED_HAND" : "RAISED_HAND";

    try {
      const response = await fetch("http://localhost:8015/api/raiseHand", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          roomID: effectiveRoom.roomID,
          userID: user.uid,
          userName: user.displayName,
          status: status,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update hand status");
      }

      setIsRaised(!isRaised);
      setToastMessage(isRaised ? "Hand lowered!" : "Hand raised!");
      setShowToast(true);
    } catch (error) {
      console.error("Error toggling hand:", error);
      setToastMessage("Error updating hand status");
      setShowToast(true);
    }
  };

  const handleQrCodeClick = () => {
    setShowQRModal(true);
  };

  const handleDocClick = () => {
    onDocClick();
  };

  const handleSlideClick = () => {
    onSlideClick();
  };
  const handleBookClick = () => {
    onBookClick();
  };
  const [loadingLogin, setLoadingLogin] = useState(true);
  const [showLogin, setShowLogin] = useState(false);

  const [showAddRoom, setShowAddRoom] = useState(false);
  const handleShowLogin = () => {
    setShowLogin(true);
  };
  const handleCloseLogin = () => {
    setShowLogin(false);
  };
  const handleCloseAddRoom = () => {
    setShowAddRoom(false);
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleOptionSelect = (selectedItem, selectedIndex) => {
    setSelectedOption(selectedItem);
    setSelectedIndex(selectedIndex);
    onOptionClick(selectedIndex + 1);
  };
  const handleTitleClick = () => {
    onTitleClick();
  };
  const handlerUpdate = () => {};
  const handlerLogout = () => {
    if (room.roomID) {
      const db = getDatabase(app);
      let link = chap ? `/chap${chap}` : "";
      const refUsers = ref(
        db,
        `/labs/${room.docID.replace(/\./g, "")}/${room.roomID}${link}/users/${
          user.uid
        }`
      );
      set(refUsers, {});
    }
    const auth = getAuth(app);
    auth.signOut();
  };
  const loadRooms = () => {
    setShowAddRoom(true);
  };

  return (
    <div className="">
      <div className="d-flex justify-content-between p-2">
        <div className="d-flex">
          <button
            class="btn btn-primary ms-1 d-xxl-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <GiHamburgerMenu size={24} />
          </button>
          {stepsData?.listChapter === null && (
            <a
              className={`ms-3 nav-link  fw-bold chapter-select`}
              href="#0"
              role="button"
              onClick={handleTitleClick}
              aria-expanded="false"
            >
              {slideTitle}
            </a>
          )}
          {stepsData?.listChapter?.length > 0 && (
            <div className="nav-center d-flex">
              {
                <div className="chapter-select-wrap">
                  <div class="dropdown">
                    {slideTitle && (
                      <>
                        <a
                          class="ms-3 nav-link  fw-bold chapter-select dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          href="#0"
                        >
                          {selectedOption || slideTitle || "NỘI DUNG"}
                        </a>
                        <ul className="dropdown-menu chapter-option">
                          {stepsData?.listChapter?.map((item, index) => (
                            <li key={index}>
                              {" "}
                              <a
                                className={`dropdown-item ${
                                  selectedIndex === index ? "highlighted" : ""
                                }`}
                                href="#1"
                                onClick={() => {
                                  handleOptionSelect(item, index);
                                }}
                              >
                                {item}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              }
            </div>
          )}
          {/* <a href="#">
            <h3 className="ms-3" onClick={() => handleTitleClick()}>
              {slideTitle}
            </h3>
          </a> */}
        </div>
        <div className="d-flex">
          {/* <button className="btn btn-primary ms-2 me-2 hide-expand">
            {user && room && (
              <RaiseHandButton roomId={room.roomID} user={user} />
            )}
          </button> */}
          <button className="btn btn-primary ms-2 me-2 hide-expand">
            <RaiseHandButton
              isRaised={isRaised}
              toggleRaiseHand={toggleRaiseHand}
            />
          </button>
          {/* refresh */}
          <>
            {teacher === user?.uid ? (
              <button className="btn btn-primary ms-2 me-2 hide-expand">
                <RxUpdate size={24} onClick={() => handlerUpdate()} />
              </button>
            ) : null}
          </>
          <div
            class="btn-group ms-2 me-2"
            role="group"
            aria-label="Basic outlined example"
          >
            <button
              type="button"
              className={`btn btn-outline-primary ${
                display === "doc" && "active"
              }`}
            >
              <IoDocumentTextOutline
                size={24}
                onClick={() => handleDocClick()}
              />
            </button>
            <button
              type="button"
              className={`btn btn-outline-primary ${
                display === "slide" && "active"
              }`}
            >
              <RiSlideshow2Line size={24} onClick={() => handleSlideClick()} />
            </button>
            <button
              type="button"
              className={`btn btn-outline-primary ${
                display === "book" && "active"
              }`}
            >
              <LuBook size={24} onClick={() => handleBookClick()} />
            </button>
          </div>
          <button className="btn btn-primary ms-2 me-2 hide-expand">
            <HiQrCode size={24} onClick={() => handleQrCodeClick()} />
          </button>
          {user && !isRoomInPath && (
            <div className="hide-expand btn-group teacher ms-2 me-2 room">
              <button
                type="button"
                className="btn btn-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <HiOutlineUserGroup
                  size={24}
                  onClick={() => handleQrCodeClick()}
                />
                <span className="d-none d-lg-inline ms-2">Phòng học</span>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button className="dropdown-item preview" onClick={loadRooms}>
                    Danh sách phòng học
                  </button>
                </li>
              </ul>
            </div>
          )}
          <div className="login ms-2 me-4  align-self-end">
            {loadingLogin ? (
              <div
                id="login-spinner"
                className="spinner-border text-light ms-3"
                role="status"
              />
            ) : user ? (
              <UserMenu user={user} onLogout={handlerLogout} />
            ) : (
              <Button variant="primary" onClick={handleShowLogin}>
                Đăng nhập
              </Button>
            )}
          </div>
          {user && showAddRoom && room && (
            <AddRoomModal
              show={user && showAddRoom}
              user={user}
              handleClose={handleCloseAddRoom}
              room={room}
            />
          )}
        </div>
      </div>

      <LoginModal
        show={!user && showLogin}
        handleClose={handleCloseLogin}
        user={user}
        onSignOut={() => {
          firebase.auth().signOut();
        }}
      />
      <QRModal
        room={room}
        show={showQRModal}
        handleClose={() => setShowQRModal(false)}
      />
    </div>
  );
};
export default Header;
