import React, { useEffect, useState } from "react";
import "./CurriculumCourse.css";
import { NavLink, useParams, useOutletContext } from "react-router-dom";

const FacultyCourses = () => {
  const [majorsList, setMajorList] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState({});
  const [test, setTest] = useState("test");
  const { slugFaculty } = useParams();
  const [curriculumData, setCurriculumData] = useState([]);
  const { data } = useOutletContext();
  useEffect(() => {
    console.log("useEffect triggered");
    const container = document.querySelector(".container");
    if (container) container.classList.add("fade-in");
    if (data.length > 0) {
      const allFaculties = data.flatMap(
        (item) => item?.attributes?.curriculum_faculties?.data
      );
      const selectedFacultyTemp = allFaculties.find(
        (faculty) => faculty?.attributes?.slug === slugFaculty
      );
      if (selectedFacultyTemp) {
        setSelectedFaculty(selectedFacultyTemp);
      }

      if (selectedFacultyTemp) {
        setMajorList(selectedFacultyTemp.attributes.curriculum_majors);
      }
    }
  }, [slugFaculty, data]);
  return (
    <div>
      <div className="container my-5 flex-grow-1" style={{ minHeight: "60vh" }}>
        <h1 className="d-flex justify-content-center mt-4 mb-4 header">
          {selectedFaculty?.attributes?.name}
        </h1>
        <p className="mb-4 description">
          {selectedFaculty?.attributes?.description}
        </p>
        <div className="row mt-4">
          <strong>
            Website :{" "}
            <a
              href={selectedFaculty?.attributes?.websiteFacultyUrl}
              target="_blank"
            >
              {selectedFaculty?.attributes?.websiteFacultyUrl}
            </a>
          </strong>
          <strong>
            Địa chỉ :{" "}
            <span>{selectedFaculty?.attributes?.addressContactFaculty}</span>
          </strong>{" "}
          <strong>
            Số điện thoại liên hệ :{" "}
            <span>{selectedFaculty?.attributes?.phoneNumberContact}</span>
          </strong>{" "}
          <strong>
            Email liên hệ :{" "}
            <span>{selectedFaculty?.attributes?.mailContact}</span>
          </strong>{" "}
        </div>

        <div className="row mt-5 justify-content-center">
          <div className="col-lg-9">
            <table className="table table-striped table-bordered text-center table-hover custom-table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">STT</th>
                  <th scope="col">Mã ngành</th>
                  <th scope="col">Mã tuyển sinh</th>
                  <th scope="col">Tên ngành</th>
                  <th scope="col">Số tín chỉ</th>
                </tr>
              </thead>
              <tbody>
                {majorsList?.data?.length > 0 &&
                  majorsList?.data.map((major, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{major?.attributes?.majorCode}</td>{" "}
                      {/* Example Major Code */}
                      <td>{major?.attributes?.admissionCode}</td>
                      <td>
                        <NavLink to="/curriculum/74801101">
                          {major?.attributes?.name}
                        </NavLink>
                      </td>
                      <td>{major?.attributes?.credits || "130"}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacultyCourses;
